import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AddressDataComponent from '../components/PayPage/AddressData/AddressDataComponent';
import CouponDataComponent from '../components/PayPage/CouponData/CouponDataComponent';
import DeliveryDataComponent from '../components/PayPage/DeliveryData/DeliveryDataComponent';
import FinalStep from '../components/PayPage/Final/FinalStep';
import LoadingProcess from '../components/PayPage/Loader/LoadingProcess';
import PaymentStep from '../components/PayPage/Payment/PaymentStep';
import PaymentDataComponent from '../components/PayPage/PaymentData/PaymentDataComponent';
import UserDataComponent from '../components/PayPage/UserData/UserDataComponent';
import { getConvertDataToFetch } from '../utils/formatAddress';
import {
  addressId,
  buildNumber,
  card,
  city,
  contactId,
  coupon,
  DATA_STEP,
  dateDraft,
  draftId,
  email,
  // entrance,
  FINAL_STEP,
  // flatNumber,
  // floor,
  fullName,
  method,
  PAY_STEP,
  phone,
  street,
  timeDraft,
  company,
  invoice,
  paypal,
} from '../constants/payData';
import { actionsCart, selectorsCart } from '../redux/cart/cartReducer';
import { actionsUser, selectorsUser } from '../redux/user/userReducer';
import service from '../services/service';
import useCreateDraft from '../useHooks/useCreateDraft';
import { getLocaleStorageItem, resetDraftId, resetPayData, setLocaleStorageItem } from '../utils/localStorageHelper';
import Timer from '../components/PayPage/Timer/Timer';
import { getWithoutCodePhone } from '../utils/normalizePhone';
import { usePaths } from '../components/Routes/RouterList';
import { actionsOrder } from '../redux/order/orderReducer';
import { buildLocale } from '../utils/buildLocale';
import { actionsOther, selectorsOther } from '../redux/other/otherReducer';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { useLessThen991 } from '../utils/mediaQuery';
import { isBrowser } from '../utils/isBrowser';
import NonAuthModal from '../components/Modals/NonAuthModal';
import { draftToDL } from '../utils/DataLayer/draftToDL';
import { useFormattingContext } from '../context/FormattingContext';
import { useForm } from 'react-hook-form';
import getDataLayer from '../utils/DataLayer/getDataLayer';
import HeaderLogo from '../components/common/HeaderLogo';
import useDebouncedFunction from '../useHooks/useDebounceFunction';
import { actionsFilters } from '../redux/filters/filtersReducer';
import { paymentTypeId } from '../constants/paymentTypeId';
import CommentDataComponent from '../components/PayPage/Comment/CommentDataComponent';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import { useNavigate } from 'react-router';
import InvoiceRequisitesDataComponent from '../components/PayPage/InvoiceRequisitesData/InvoiceRequisitesDataComponent';
import {
  canInvoiceRequisitesDataBeProcessing,
  isInvoiceRequisitesDataValidToSave,
} from '../utils/invoiceOrderRequisitesDataHelper';

const PayPage = () => {
  // const paths = usePaths();
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const createDraft = useCreateDraft();
  const debCreateDraft = useDebouncedFunction(createDraft, 1000);
  const profiles = useSelector(selectorsUser.getContacts);
  const addresses = useSelector(selectorsUser.getAddresses);
  const items: any = useSelector(selectorsCart.getCartProducts);
  const user = useSelector(selectorsUser.getUser);
  const isAuth = useSelector(selectorsUser.isAuth);
  const navigate = useNavigate();
  const paths = usePaths();
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const cart = useSelector(selectorsCart.getCart);
  const sum = useSelector(selectorsCart.getProductsPrice);
  const minPrice = useSelector(selectorsOther.getMinDeliveryPrice);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const notAvailableProducts = useSelector(selectorsCart.getNotAvailableProducts);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [payStep, setPayStep] = useState(DATA_STEP);
  const [finalData, setFinalData] = useState(null);
  const [isUserChecked, setIsUserChecked] = useState(false);
  const [isAddressChecked, setIsAddressChecked] = useState(false);
  const [isValidatedCoupon, setIsValidatedCoupon] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState('');
  const [draftData, setDraftData] = useState<any>(null);
  const [salePrice, setSalePrice] = useState<number | null>(null);
  const [couponInfo, setCouponInfo] = useState('');
  const isNonAuthOpen = useSelector(selectorsOther.getNonAuth);
  const zipCode = useSelector(selectorsDelivery.getZipCode);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const { formatPrice } = useFormattingContext();
  const paymentMethod = getLocaleStorageItem(method);
  //TODO: fix
  const [isSelectAddress, setIsSelectAddress] = useState(false);
  const [isInvoiceAddressTheSameAsOrderAddress, setIsInvoiceAddressTheSameAsOrderAddress] = useState(true);
  const isAlreadyDisabled =
    !items || items.length === 0 || minPrice > (sum || 0) || (notAvailableProducts && notAvailableProducts.length > 0);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    resetField,
    trigger,
  } = useForm({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  useEffect(() => {
    dispatch(actionsFilters.clearAllSelected());
    if (canInvoiceRequisitesDataBeProcessing()) {
      setIsInvoiceAddressTheSameAsOrderAddress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetChecked = () => {
    setIsUserChecked(false);
    setIsAddressChecked(false);
    setIsValidatedCoupon(false);
  };

  useEffect(() => {
    //TODO: hook
    if (isBrowser && draftData && payStep === DATA_STEP) {
      getDataLayer().push({
        event: 'PAYMENT_STEP',
        custom_title: DATA_STEP,
        ecommerce: draftToDL(draftData),
      });
    }
  }, [payStep, draftData]);

  useEffect(() => {
    setIsDisabled(
      !items ||
        items.length === 0 ||
        minPrice > (sum || 0) ||
        (notAvailableProducts && notAvailableProducts.length > 0) ||
        !paymentMethod,
    );
  }, [items, minPrice, notAvailableProducts, sum, paymentMethod]);

  useEffect(() => {
    const queryParams = location && new URLSearchParams(location.search);
    if (queryParams && queryParams.get('payment_intent')) {
      setIsLoading(true);
      service
        .validateClientKey(queryParams.get('payment_intent'))
        .then((res: any) => {
          if (res.success) {
            setIsLoading(true);
            createOrder();
          }
        })
        .catch(() => setIsLoading(false));
      return;
    }
    if (items.length > 0) {
      createDraft()
        .then((res) => {
          if (res.success) {
            setDraftData(res.data);
            if (getLocaleStorageItem(draftId)) {
              validateCoupon();
            }
            setIsLoading(false);
          }
        })
        .catch(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUserChecked && isValidatedCoupon && isAddressChecked) {
      createDraft()
        .then((res) => {
          if (res.success) {
            setDraftData(res.data);
            if (getLocaleStorageItem(method) === invoice) {
              createOrder();
              setIsLoading(false);
              return;
            }
            setPayStep(PAY_STEP);
            getDataLayer().push({
              event: 'PAYMENT_STEP',
              custom_title: PAY_STEP,
              ecommerce: draftToDL(res.data),
            });
            setIsLoading(false);
          }
        })
        .catch(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserChecked, isValidatedCoupon, isAddressChecked]);

  const validateCoupon = () => {
    const couponCode = getLocaleStorageItem(coupon);
    if (!couponCode) {
      setIsValidatedCoupon(true);
      return;
    }
    setIsLoading(true);
    setLoadingMessage(buildLocale(currentTranslate, 'payPageLoadingCoupon'));
    const draft = getLocaleStorageItem(draftId);
    service
      .validateCoupon({
        code: couponCode,
        orderId: draft ? +draft : null,
        phoneNumber: getLocaleStorageItem(phone),
      })
      .then((res) => {
        if (!res.success) {
          return;
        }
        setSalePrice(res.data.totalPrice);
        dispatch(actionsOrder.setCouponId(res.data.coupon.id));
      })
      .finally(() => {
        setIsValidatedCoupon(true);
        setLoadingMessage('');
      });
  };

  const checkUserData = () => {
    //TODO: refactor
    const isMainUser =
      user &&
      getLocaleStorageItem(email) === user.email &&
      getLocaleStorageItem(fullName) === user.fullName &&
      getWithoutCodePhone(getLocaleStorageItem(phone) || '') === user.phone;

    const currentProfile = profiles.find(
      (profile) =>
        profile.fullName === getLocaleStorageItem(fullName) &&
        profile.email === getLocaleStorageItem(email) &&
        profile.phone === getWithoutCodePhone(getLocaleStorageItem(phone) || ''),
    );

    if (isMainUser) {
      setIsUserChecked(true);
      return;
    }

    if (currentProfile) {
      setLocaleStorageItem(contactId, currentProfile.id.toString());
      setIsUserChecked(true);
    } else {
      setIsLoading(true);
      setLoadingMessage(buildLocale(currentTranslate, 'payPageLoadingProfile'));
      const contact = {
        fullName: getLocaleStorageItem(fullName),
        phone: getWithoutCodePhone(getLocaleStorageItem(phone) || ''),
        email: getLocaleStorageItem(email),
        company: getLocaleStorageItem(company),
      };

      service
        .addContact(contact)
        .then((value) => {
          dispatch(actionsUser.addContact(value.data));
          setLocaleStorageItem(contactId, value.data.id.toString());
          setIsUserChecked(true);
        })
        .catch((e) => {
          setIsUserChecked(true);
        });
    }
  };

  const checkAddress = () => {
    //TODO: refactor

    if ((!addresses || addresses.length === 0) && !getLocaleStorageItem('asGuest') && isAuth) {
      addAddress();
      addUserAddress();
      return;
    }
    const currentAddress = addresses.find(
      (address) =>
        // (address.entrance === getLocaleStorageItem(entrance) ||
        //   (!!!address.entrance && !!!getLocaleStorageItem(entrance))) &&
        address.zipCode === zipCode &&
        address.buildNumber === getLocaleStorageItem(buildNumber) &&
        // (address.flatNumber === getLocaleStorageItem(flatNumber) ||
        //   (!!!address.flatNumber && !!!getLocaleStorageItem(flatNumber))) &&
        address.city === getLocaleStorageItem(city) &&
        // (address.floor === getLocaleStorageItem(floor) || (!!!address.floor && !!!getLocaleStorageItem(floor))) &&
        address.street === getLocaleStorageItem(street),
    );
    addAddress();
    if (!currentAddress) {
      addUserAddress();
    }
  };

  const convertToAddress = () => {
    return getConvertDataToFetch({
      city: getLocaleStorageItem(city) as string,
      // entrance: getLocaleStorageItem(entrance) as string,
      // flatNumber: getLocaleStorageItem(flatNumber) as string,
      buildNumber: getLocaleStorageItem(buildNumber) as string,
      // floor: getLocaleStorageItem(floor) as string,
      street: getLocaleStorageItem(street) as string,
      zipCode,
      district: '',
    });
  };

  const addAddress = () => {
    setIsLoading(true);
    setLoadingMessage(buildLocale(currentTranslate, 'payPageLoadingAddress'));
    service
      .addOrderAddress(convertToAddress())
      .then((res) => {
        if (res.success) {
          setLocaleStorageItem(addressId, res.data.id.toString());
        }
        setLoadingMessage('');
      })
      .finally(() => setIsAddressChecked(true));
  };

  const addUserAddress = () => {
    service.addAddress(convertToAddress()).then((res) => {
      if (res.success) {
        dispatch(actionsUser.addAddress(res.data));
      }
    });
  };

  const createOrder = () => {
    const draft = getLocaleStorageItem(draftId);
    if (!draft) {
      return;
    }
    const paymentMethodId = () => {
      switch (paymentMethod) {
        case card:
          return paymentTypeId.card;
        case invoice:
          return paymentTypeId.invoice;
        case paypal:
          return paymentTypeId.paypal;
        default:
          return paymentTypeId.card;
      }
    };
    setIsLoading(true);
    const data = {
      idCity: 1,
      draftId: +draft,
      products: items,
      data: {
        paymentType: { id: paymentMethodId() },
        deliveryStatus: { id: 1 },
        deliveryType: {
          id: isInCourierArea ? 1 : 5,
        },
      },
    };
    service
      .createOrder(data)
      .then((res) => {
        if (res.success) {
          setFinalData(res.data);
          addProductsToWishlist(res.data.purchases.map((item) => item.product.id));
          setPayStep(FINAL_STEP);
          resetPayData();
          resetDraftId();
          dispatch(actionsOrder.setCouponId(null));
          resetChecked();
          dispatch(actionsCart.clear());
          getDataLayer().push({
            event: 'PAYMENT_STEP',
            custom_title: FINAL_STEP,
            ecommerce: draftToDL(res.data),
          });
          service
            .refreshUser()
            .then((resp) => {
              if (resp) {
                dispatch(actionsUser.setData(resp));
              }
            })
            .catch((e) => console.error('ERROR'));
        }
      })
      .finally(() => setIsLoading(false));
  };

  const addProductsToWishlist = (products: Array<number>) => {
    service.addProductsToWishlist(products).catch((e) => console.error(e));
  };

  const checkFields = () => {
    return zipCode &&
      getLocaleStorageItem(buildNumber) &&
      getLocaleStorageItem(street) &&
      getLocaleStorageItem(fullName) &&
      getLocaleStorageItem(phone) &&
      getLocaleStorageItem(email) &&
      getLocaleStorageItem(city) &&
      isInCourierArea
      ? getLocaleStorageItem(dateDraft) && getLocaleStorageItem(timeDraft)
      : true;
  };

  const onSubmit = () => {
    if (!checkFields()) {
      setError(buildLocale(currentTranslate, 'payPageFieldError'));
      return;
    }
    setIsLoading(true);
    checkUserData();
    checkAddress();
    validateCoupon();
  };

  const isValidAddressData = () => {
    return getLocaleStorageItem(city) && getLocaleStorageItem(street) && getLocaleStorageItem(buildNumber);
  };

  const isAvailableSaveButtonForInvoiceRequisitesData = () => {
    if (isInvoiceAddressTheSameAsOrderAddress) {
      return true;
    }
    return isInvoiceRequisitesDataValidToSave();
  };

  const closePayPage = () => {
    if (isLess991) {
      navigate(paths.home);
      dispatch(actionsCart.toggleCart(true));
      return;
    }
    navigate(paths['cart-list']);
  };

  const closeNonAuth = () => {
    dispatch(actionsOther.setIsOpenNonAuth(false));
  };

  if (payStep === PAY_STEP && draftData) {
    return (
      <PaymentStep
        createOrder={createOrder}
        draftData={draftData}
        setPayStep={setPayStep}
        resetChecked={resetChecked}
      />
    );
  }
  if (payStep === FINAL_STEP) {
    return <FinalStep finalData={finalData} />;
  }

  return (
    <section className="pay-page">
      <header className="pay-page_header">
        <HeaderLogo />
        <span className="pay-page_close" onClick={() => closePayPage()}></span>
      </header>
      {isDisabled && minPrice > (sum || 0) && (
        <div className="pay-error">
          {buildLocale(currentTranslate, 'toLowOrderSum')} {formatPrice(minPrice)}
        </div>
      )}
      {isDisabled && (!items || items.length === 0) && (
        <div className="pay-error">{buildLocale(currentTranslate, 'payPageNoProductError')}</div>
      )}
      {isDisabled && notAvailableProducts.length > 0 && (
        <div className="pay-error">{buildLocale(currentTranslate, 'payPageNonAvailable')}</div>
      )}
      {isLoading && (
        <div className="pay-loader">
          <LoadingProcess isLoading={isLoading} loadingMessage={loadingMessage} />
        </div>
      )}
      <div className="container">
        <h1>{buildLocale(currentTranslate, 'payPageOrder')}</h1>
        <Timer />
        <div className="pay-block">
          <div className="pay-block_one">
            <UserDataComponent
              setIsDisabled={setIsDisabled}
              isAlreadyDisabled={isAlreadyDisabled}
              errors={errors}
              register={register}
              debCreateDraft={debCreateDraft}
              setCouponInfo={setCouponInfo}
              setSalePrice={setSalePrice}
              setValue={setValue}
            />
          </div>
          <div className="pay-block_one">
            <AddressDataComponent
              errors={errors}
              register={register}
              debCreateDraft={debCreateDraft}
              isSelectAddress={isSelectAddress}
              setIsSelectAddress={setIsSelectAddress}
              setValue={setValue}
            />
          </div>
          <div className="pay-block_one">
            <InvoiceRequisitesDataComponent
              errors={errors}
              register={register}
              debCreateDraft={debCreateDraft}
              getValues={getValues}
              setValue={setValue}
              resetField={resetField}
              trigger={trigger}
              isInvoiceAddressTheSameAsOrderAddress={isInvoiceAddressTheSameAsOrderAddress}
              setIsInvoiceAddressTheSameAsOrderAddress={setIsInvoiceAddressTheSameAsOrderAddress}
            />
          </div>
          <div className="pay-block_one">
            {isInCourierArea ? (
              <DeliveryDataComponent error={error} setError={setError} debCreateDraft={debCreateDraft} />
            ) : (
              <CommentDataComponent debCreateDraft={debCreateDraft} />
            )}
          </div>
          <div className="pay-block_one">
            <CouponDataComponent setSalePrice={setSalePrice} couponInfo={couponInfo} setCouponInfo={setCouponInfo} />
          </div>
          <div className="pay-block_one">
            <PaymentDataComponent />
          </div>
        </div>
        {error && <p className="pay-page_fill">{error}</p>}
        <div className="pay-total">
          <div className="caption">
            {buildLocale(currentTranslate, 'mobileCouponAccepted')}{' '}
            <span className={`${salePrice ? 'price pay-price-old' : 'price'}`}>{formatPrice(cart.total || 0)}</span>
            {salePrice && <span className="price pay-price-sale"> {formatPrice(salePrice)}</span>}
          </div>
          <button
            className="btn"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
            disabled={isDisabled || !isValidAddressData() || !isAvailableSaveButtonForInvoiceRequisitesData()}>
            {buildLocale(currentTranslate, 'commonPayment')}
          </button>
        </div>
      </div>

      <ul className="footer-menu">
        <li>
          <Link to={paths.about_us}>{buildLocale(currentTranslate, 'footerAboutUs')}</Link>
        </li>
        <li>
          <Link to={paths.regional_products}>{buildLocale(currentTranslate, 'footerRegionalProducts')}</Link>
        </li>
        <li>
          <Link to={paths.regional_partners}>{buildLocale(currentTranslate, 'footerRegionalPartners')}</Link>
        </li>
        <li>
          <Link to={paths.brands}>{buildLocale(currentTranslate, 'brandsFooter')}</Link>
        </li>
        <li>
          <Link to={paths.bio_badges}>{buildLocale(currentTranslate, 'footerBioBadges')}</Link>
        </li>
        <li>
          <Link to={paths.delivery}>{buildLocale(currentTranslate, 'footerDelivery')}</Link>
        </li>
        <li>
          <Link to={paths.produktrueckrufe}>{buildLocale(currentTranslate, 'footerProduktrueckrufe')}</Link>
        </li>
        <li>
          <Link to={paths.presse}>{buildLocale(currentTranslate, 'footerPresse')}</Link>
        </li>
        <li>
          <a href="https://join.com/companies/mybio" target="_blank" rel="noreferrer">
            {buildLocale(currentTranslate, 'footerCarreer')}
          </a>
        </li>
        <li>
          <Link to={paths.faq}>{buildLocale(currentTranslate, 'footerFaq')}</Link>
        </li>
        <li>
          <Link to={paths.agb}>{buildLocale(currentTranslate, 'footerAgb')}</Link>
        </li>
        <li>
          <Link to={paths.widerrufsrecht}>{buildLocale(currentTranslate, 'footerWiderrufsrecht')}</Link>
        </li>
        <li>
          <Link to={paths.confidentiality}>{buildLocale(currentTranslate, 'footerConfid')}</Link>
        </li>
        <li>
          <Link to={paths.impressum}>{buildLocale(currentTranslate, 'footerImpressum')}</Link>
        </li>
      </ul>
      {isNonAuthOpen && <NonAuthModal isOpen={isNonAuthOpen} onClose={closeNonAuth} />}
    </section>
  );
};

export default PayPage;
