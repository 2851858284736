import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { selectorsUser } from '../../../redux/user/userReducer';
import service from '../../../services/service';
import { buildLocale } from '../../../utils/buildLocale';
import ReviewStars from './ReviewStars';
import config from '../../../config';
import ReCAPTCHA from 'react-google-recaptcha';
import { actionsOther } from '../../../redux/other/otherReducer';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getClientShortNameWithPoint } from '../../../utils/clientNameHelper';

interface IManageProductReviewWidgetProps {
  productId: number;
  onAfterSubmit?: () => void;
}

const ManageProductReviewWidget = ({ productId, onAfterSubmit }: IManageProductReviewWidgetProps) => {
  const DEFAULT_RATE = 5;
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const orderedAt = searchParams.get('ordered_at');
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [rating, setRating] = useState(DEFAULT_RATE);
  const [text, setText] = useState('');

  const currentUserFullName = useSelector(selectorsUser.getFullName);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorShowMessage, setIsErrorShowMessage] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>();

  const handleSubmit = async (e: any) => {
    setIsLoading(true);
    setIsErrorShowMessage(false);
    e.preventDefault();
    await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    const res = await service.addReview({
      productId,
      data: {
        rating,
        text,
        order: orderedAt?.length ? { id: orderedAt } : null,
      },
    });
    if (res.success) {
      setRating(DEFAULT_RATE);
      setText('');
      if (onAfterSubmit) {
        onAfterSubmit();
      }
      setIsLoading(false);
      return;
    }
    setIsErrorShowMessage(true);
    setIsLoading(false);
  };

  const isDisabled = rating === 0;
  return (
    <div className="product-reviews_bottom">
      <h3>{buildLocale(currentTranslate, 'writeReview')}</h3>
      <div className="product-reviews_info-block">
        {buildLocale(currentTranslate, 'reviewsWillAppearAfterVerification')}
      </div>
      <form className="product-reviews_form" onSubmit={handleSubmit}>
        <div className="product-reviews_form-rating">
          <div className="name-container">
            {!!currentUserFullName?.length && <>{getClientShortNameWithPoint(currentUserFullName)}</>}
            {!currentUserFullName?.length && (
              <>
                <span className="name-container_anonym-title">Anonym</span>
                <span
                  className="name-container_anonym-message"
                  onClick={() =>
                    dispatch(
                      actionsOther.setOpenLoginData({
                        isOpenLogin: true,
                        redirectLink: location.pathname,
                      }),
                    )
                  }>
                  {buildLocale(currentTranslate, 'loginToLeavePersonalReview')}
                </span>
              </>
            )}
          </div>
          <ReviewStars isEditable ratingValue={rating} onSubmit={(n) => setRating(n)} />
        </div>
        <textarea
          placeholder={buildLocale(currentTranslate, 'yourOpinion')}
          value={text}
          onChange={(e) => setText(e.currentTarget.value)}
          className="form-control form-dark"></textarea>
        <div className="product-reviews_form-bottom">
          <button type="submit" disabled={isLoading || isDisabled} className="btn btn-blue">
            {buildLocale(currentTranslate, 'btnSave')}
          </button>
        </div>
      </form>
      {isErrorShowMessage && <p>{buildLocale(currentTranslate, 'errorClient')}</p>}
      <ReCAPTCHA
        sitekey={config.recaptchaPublicSiteKey}
        size="invisible"
        ref={recaptchaRef}
        className="recaptcha-registr"
      />
    </div>
  );
};

export default ManageProductReviewWidget;
