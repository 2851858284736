import { FilterSearchingParams } from '../redux/filters/filtersTypes';

const getSortDataForFilter = (
  searchQueryData: FilterSearchingParams,
  selectedSortData: string | undefined, // price asc | price desc | discount desc
  sortedEntityPath = 'products/productOptions/',
): string => {
  if (searchQueryData.isEmpty) {
    return !selectedSortData?.length ? '' : `${sortedEntityPath}${selectedSortData}`;
  }

  return !searchQueryData.sortDirection?.length ? '' : `${sortedEntityPath}${searchQueryData.sortDirection}`;
};

export default getSortDataForFilter;
