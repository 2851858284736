import { useDispatch, useSelector } from 'react-redux';
import {
  addressId,
  buildNumber,
  city,
  comment,
  contactId,
  coupon,
  credits,
  dateDraft,
  draftId,
  email,
  // entrance,
  // flatNumber,
  // floor,
  freeBag,
  fullName,
  phone,
  street,
  timeDraft,
  company,
  invoiceRequisitesPerson,
  invoiceRequisitesCompany,
  invoiceRequisitesCity,
  invoiceRequisitesZipCode,
  invoiceRequisitesStreet,
  invoiceRequisitesBuildNumber,
  invoiceRequisitesVatId,
} from '../constants/payData';
import { actionsCart, selectorsCart } from '../redux/cart/cartReducer';
import { selectorsLocale } from '../redux/locale/localeReducer';
import { actionsOrder, selectorsOrder } from '../redux/order/orderReducer';
import service from '../services/service';
import { IDraftPay } from '../typings/IDraftPay';
import { formatAddress } from '../utils/formatAddress';
import { getLocaleStorageItem, setLocaleStorageItem } from '../utils/localStorageHelper';
import { selectorsUser } from '../redux/user/userReducer';
import { selectorsDelivery } from '../redux/delivery/deliveryReducer';
import { isInvoiceRequisitesDataAvailable } from '../utils/invoiceOrderRequisitesDataHelper';

const useCreateDraft = () => {
  const dispatch = useDispatch<any>();
  const productItems = useSelector(selectorsCart.getCartProducts);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const couponId: any = useSelector(selectorsOrder.getCouponId);
  const user = useSelector(selectorsUser.getDataUser);
  const zipCode = useSelector(selectorsDelivery.getZipCode);

  const getFetchData = () => {
    const time = getLocaleStorageItem(timeDraft);
    const date = getLocaleStorageItem(dateDraft);
    let minExecuteDate: any, maxExecuteDate: any;

    if (time && date) {
      const [minTime, maxTime] = time.split('-');
      if (maxTime && minTime) {
        const [maxH, maxM] = maxTime.split(':').map(parseFloat);
        const [minH, minM] = minTime.split(':').map(parseFloat);

        maxExecuteDate = new Date(date);
        maxExecuteDate.setHours(maxH, maxM);

        minExecuteDate = new Date(date);
        minExecuteDate.setHours(minH, minM);
      }
    }
    const uniqueIds = new Set();
    const uniqueProductItems = productItems.filter((obj) => {
      const productId = obj.product.id;
      if (!uniqueIds.has(productId)) {
        uniqueIds.add(productId);
        return true;
      }
      return false;
    });

    const draft = getLocaleStorageItem(draftId);
    const fetchData: IDraftPay = {
      purchases: uniqueProductItems.map((item) => {
        return { product: { id: item.product.id }, count: item.count };
      }),
      id: draft ? +draft : null,
      fullName: getLocaleStorageItem(fullName),
      company: getLocaleStorageItem(company),
      phone: getLocaleStorageItem(phone),
      email: getLocaleStorageItem(email),
      orderAddress: formatAddress(
        {
          city: getLocaleStorageItem(city),
          // flatNumber: getLocaleStorageItem(flatNumber),
          zipCode,
          // entrance: getLocaleStorageItem(entrance),
          // floor: getLocaleStorageItem(floor),
          street: getLocaleStorageItem(street),
          buildNumber: getLocaleStorageItem(buildNumber),
        },
        currentTranslate,
      ),
      sellPoint: 1,
      freeBag: !!getLocaleStorageItem(freeBag),
      contact: getLocaleStorageItem(contactId) ? { id: +(getLocaleStorageItem(contactId) as string) } : null,
      minExecuteDate,
      maxExecuteDate,
      deliveryPrice: 2,
      comment: getLocaleStorageItem(comment),
      zipCode,
    };

    if (isInvoiceRequisitesDataAvailable()) {
      fetchData.orderInvoiceRequisites = {
        personName: getLocaleStorageItem(invoiceRequisitesPerson) || null,
        companyName: getLocaleStorageItem(invoiceRequisitesCompany) || null,
        city: getLocaleStorageItem(invoiceRequisitesCity) || null,
        zipCode: getLocaleStorageItem(invoiceRequisitesZipCode) || null,
        street: getLocaleStorageItem(invoiceRequisitesStreet) || null,
        buildNumber: getLocaleStorageItem(invoiceRequisitesBuildNumber) || null,
        vatId: getLocaleStorageItem(invoiceRequisitesVatId) || null,
      };
    } else {
      fetchData.orderInvoiceRequisites = null;
    }

    if (getLocaleStorageItem(credits) && user?.credits?.balance && +user?.credits?.balance) {
      fetchData.creditsUsed = user?.credits?.balance;
    }

    if (couponId && getLocaleStorageItem(coupon)) {
      fetchData.coupon = { id: couponId };
    }

    const addressDraftId = getLocaleStorageItem(addressId);
    if (addressDraftId) {
      fetchData.address = { id: +addressDraftId };
    }

    return fetchData;
  };

  return async () => {
    const fetchData = getFetchData();
    let res = await service.createDraft(fetchData);
    if (res.code === 401) {
      return Promise.reject(res);
    }
    if (res.success) {
      setLocaleStorageItem(draftId, res.data.id);
    }
    if (!res.success) {
      dispatch(
        actionsOrder.setData({
          draftId: null,
        }),
      );
      fetchData.id = null;
      res = await service.createDraft(fetchData);
      if (res.success) {
        setLocaleStorageItem(draftId, res.data.id);
        dispatch(
          actionsOrder.setData({
            draftId: res.data.id,
          }),
        );
      }
    }
    if (res.data.notAvailableProducts) {
      dispatch(actionsCart.setNotAvailableProducts(res.data.notAvailableProducts));
    }

    return res.success ? Promise.resolve(res) : Promise.reject(res);
  };
};

export default useCreateDraft;
