import { CreatorReducer } from '../base/base';
import { IUserActions, IUserState } from './userTypes';
import service from '../../services/service';
import { RootState } from '../reducer';
import { IAddress } from '../../typings/IAddress';
import { ISignUp } from '../../typings/ISignUp';
import { ICard } from '../../typings/ICard';
import { IUser } from '../../typings/IUser';
import { removeFromLocaleStorage, resetDraftId, resetPayData } from '../../utils/localStorageHelper';
import { createSelector } from 'reselect';
import { getClientFullName } from '../../utils/clientNameHelper';

const init: IUserState = {
  data: null,
  error: false,
  isLoading: false,
  newAddress: null,
  errorInfo: null,
};

const creator = new CreatorReducer<IUserActions, IUserState>('user');
creator.addAction('logout', (state, action) => {
  return { ...state, data: null };
});
creator.addAction<IUser>('updateUser', (state, action) => {
  const user = { ...state.data, ...action.payload };
  return { ...state, data: user };
});
creator.addAction('addAddress', (state, action) => {
  const user = { ...state.data! };
  user.addresses = [...user.addresses, action.payload];
  return { ...state, data: user };
});
creator.addAction('addNewAddress', (state, action) => {
  return { ...state, newAddress: action.payload };
});
creator.addAction<IAddress>('updateAddress', (state, action) => {
  const index = state.data!.addresses.findIndex((a) => a.id === action.payload.id);
  if (index === -1) return state;
  const user = { ...state.data! };
  user.addresses[index] = action.payload;
  user.addresses = [...user.addresses];
  return { ...state, data: user };
});
creator.addAction('deleteAddress', (state, action) => {
  if (action.payload) {
    let addresses = state.data!.addresses;
    addresses = addresses.filter((a) => a.id !== action.payload);
    state.data!.addresses = addresses;
    return { ...state };
  }
  return state;
});
creator.addAction('addContact', (state, action) => {
  const user = { ...state.data! };
  user.contacts = [...user.contacts, action.payload];
  return { ...state, data: user };
});
creator.addAction('updateContact', (state, action) => {
  const index = state.data!.contacts.findIndex((a) => a.id === action.payload.id);
  if (index === -1) return state;
  const user = { ...state.data! };
  user.contacts[index] = action.payload;
  return { ...state, data: user };
});
creator.addAction('deleteContact', (state, action) => {
  if (action.payload) {
    let contacts = state.data!.contacts;
    contacts = contacts.filter((a) => a.id !== action.payload);
    state.data!.contacts = contacts;
    return { ...state };
  }
  return state;
});
creator.addAction('deleteCard', (state, action) => {
  if (action.payload) {
    let creditCards = state.data!.creditCards;
    creditCards = creditCards.filter((a) => a.id !== action.payload);
    state.data!.creditCards = creditCards;
    return { ...state };
  }
  return state;
});
creator.addAction<ICard[]>('setCards', (state, action) => {
  if (action.payload) {
    state.data!.creditCards = action.payload;
    return { ...state };
  }
  return state;
});
creator.addAction('addCard', (state, action) => {
  const user = { ...state.data! };
  user.creditCards = [...user.creditCards, action.payload];
  return { ...state, data: user };
});
creator.addAction('setErrorInfo', (state, action) => {
  return { ...state, errorInfo: action.payload };
});
const actionsUser = creator.createActions();

const fetchLogin = (phone: string, password: string) => async (dispatch: any) => {
  return fetchRedux(
    async () => await service.login(phone, password),
    (data: any) => dispatch(actionsUser.setData(data)),
  )(dispatch);
};
const fetchLoginEmail = (email: string, password: string) => async (dispatch: any) => {
  return fetchRedux(
    async () => await service.loginEmail(email, password),
    (data: any) => dispatch(actionsUser.setData(data)),
  )(dispatch);
};
const fetchImpersonate = (clientId: string, tokenHash: string) => async (dispatch: any) => {
  return fetchRedux(
    async () => await service.impersonate(clientId, tokenHash),
    (data: any) => dispatch(actionsUser.setData(data)),
  )(dispatch);
};
const fetchCheckToken = (body: any) => async (dispatch: any) => {
  return fetchRedux(
    async () => await service.confirmRegistration(body),
    (data: any) => {
      dispatch(actionsUser.setData(data));
    },
  )(dispatch);
};
const fetchSignup = (data: ISignUp) => async (dispatch: any) => {
  return fetchRedux(
    async () => await service.signup(data),
    //(data: any) => dispatch(actionsUser.setData(data))
    (data: any) => null,
  )(dispatch);
};
const fetchLogout = async (dispatch: any) => {
  const res = await service.logout();
  resetPayData();
  resetDraftId();
  removeFromLocaleStorage('asGuest');
  if (res) {
    dispatch(actionsUser.logout());
  }
};

const fetchRedux = (fetch: any, saveData: any) => async (dispatch: any) => {
  dispatch(actionsUser.setLoading(true));
  const res = await fetch();

  if (res.success) {
    saveData(res.data);
  } else {
    if (res.codeInfo) {
      dispatch(actionsUser.setErrorInfo(res.codeInfo));
    }
    dispatch(actionsUser.setError(res.data));
  }

  dispatch(actionsUser.setLoading(false));
  return res;
};

const refreshUser = async (dispatch: any) => {
  const res = await service.refreshUser();
  if (res) {
    dispatch(actionsUser.setData(res));
  }
};

/**
 * Login with the Firebase auth flow
 * @param token Firebase token id
 * @returns
 */
const firebaseLogin = (token: string) => async (dispatch: any) => {
  return fetchRedux(
    async () => await service.firebaseLogin(token),
    (data: any) => dispatch(actionsUser.setData(data)),
  )(dispatch);
};

const selectDataUser = (state: RootState) => state.user.data;

const getDataUser = createSelector([selectDataUser], (userData) => {
  if (!userData) {
    return null;
  }
  return {
    id: 1,
    name: getClientFullName(userData.fullName, userData.firstName, userData.lastName),
    phone: userData.phone,
    isPhoneCustom: userData.isPhoneCustom,
    email: userData.email,
    lastName: userData.lastName,
    firstName: userData.firstName,
    fullName: userData.fullName,
    credits: userData.clientCredit,
    company: userData.company,
  };
});

const selectorsUser = {
  getUser: selectDataUser,
  getPhone: (state: RootState) => (state.user.data ? state.user.data.phone : ''),
  getName: (state: RootState) => {
    return state.user.data ? state.user.data.firstName : '';
  },
  getFullName: (state: RootState) => {
    return state.user.data
      ? getClientFullName(state.user.data.fullName, state.user.data.firstName, state.user.data.lastName)
      : '';
  },
  getError: (state: RootState) => state.user.error,
  getLoading: (state: RootState) => state.user.isLoading,
  isAuth: (state: RootState) => state.user.data !== null,
  getAddressById: (id?: number | null) => (state: RootState) => {
    if (state.user.data) return state.user.data!.addresses.find((a) => a.id === id);
    return null;
  },
  getAddresses: (state: RootState) => {
    if (state.user.data === null) return [];
    return state.user.data.addresses || [];
  },
  getProductSubscriptionByProductOptionId: (id?: number | null) => (state: RootState) => {
    if (state.user.data) return state.user.data!.productsSubscriptions.find((a) => a.productOption.id === id);
    return null;
  },
  getProductsSubscriptions: (state: RootState) => {
    if (state.user.data === null) return [];
    return state.user.data.productsSubscriptions || [];
  },
  getContacts: (state: RootState) => {
    if (state.user.data === null) return [];
    return state.user.data.contacts || [];
  },
  isEmptyContacts: (state: RootState) => {
    const contacts = selectorsUser.getContacts(state);
    return contacts.length === 0;
  },
  getDataUser,
  getCards: (state: RootState) => {
    if (state.user.data) {
      return state.user.data.creditCards || [];
    }
    return [];
  },
  getNewAddress: (state: RootState) => {
    return state.user.newAddress;
  },
  getErrorInfo: (state: RootState) => state.user.errorInfo,
};

export {
  actionsUser,
  selectorsUser,
  fetchLogin,
  fetchSignup,
  fetchLogout,
  refreshUser,
  fetchLoginEmail,
  fetchCheckToken,
  firebaseLogin,
  fetchImpersonate,
};

export default creator.createReducerFetch(init);
