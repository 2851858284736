import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectorsUser } from '../../../redux/user/userReducer';
import UserProfilesDropdown from './UserProfilesDropdown';
import { getLocaleStorageItem, removeFromLocaleStorage, setLocaleStorageItem } from '../../../utils/localStorageHelper';
import { email, phone, coupon, fullName, company } from '../../../constants/payData';
import ItemInput from '../../Forms/Components/ItemInput';
import validation from '../../../utils/validation';
import { buildLocale } from '../../../utils/buildLocale';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import PhoneInput from 'react-phone-input-2';
import service from '../../../services/service';
import { Link } from 'react-router-dom';
import { usePaths } from '../../Routes/RouterList';
import { IRegister } from '../../../typings/IRegister';
import ShowError from '../../Forms/Components/ShowError';
import { FieldValue, UseFormSetValue } from 'react-hook-form';

interface IProps {
  setIsDisabled: (isDisabled: boolean) => void;
  isAlreadyDisabled: boolean;
  errors: any;
  register: (name: string, validation: any) => IRegister;
  debCreateDraft: () => void;
  setValue: UseFormSetValue<FieldValue<any>>;
  setCouponInfo: (info: string) => void;
  setSalePrice: (price: number | null) => void;
}

const UserDataComponent = (props: IProps) => {
  const { setIsDisabled, isAlreadyDisabled, errors, register, debCreateDraft, setValue, setCouponInfo, setSalePrice } =
    props;
  const currentUser = useSelector(selectorsUser.getDataUser);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const isAuth = useSelector(selectorsUser.isAuth);
  const paths = usePaths();
  const [userData, setUserData] = useState<any>(null);
  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const isNonAuth = getLocaleStorageItem('asGuest') && !isAuth;
  const emailRegister = register('email', validation.email);
  const phoneRegister = register('phone', validation.phone);

  useEffect(() => {
    setInitialUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNonAuth && userEmail) {
      const delayDebounceFn = setTimeout(() => {
        validateEmail();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  const setInitialUser = () => {
    setInitialHelper(phone);
    setInitialHelper(email);
  };

  const setInitialHelper = (key: string) => {
    const keyValue = getLocaleStorageItem(key);
    if (key === email && keyValue) {
      setUserEmail(keyValue);
    }

    if (keyValue) {
      setUserData((prevData: any) => ({ ...prevData, [key]: keyValue }));
      setValue(key, keyValue);
    } else if (currentUser) {
      setUserData((prevData: any) => ({ ...prevData, [key]: currentUser[key] }));
      setValue(key, currentUser[key]);
      setLocaleStorageItem(key, currentUser[key] || '');
    }
  };

  const updateStorageUser = (user: any) => {
    setLocaleStorageItem(email, user?.email || '');
    setLocaleStorageItem(phone, user?.phone || '');

    setLocaleStorageItem(fullName, user?.fullName || '');
    setLocaleStorageItem(company, user?.company || '');
  };

  const changeData = (key: string, value: string) => {
    setLocaleStorageItem(key, value);
    setUserData({ ...userData, [key]: value });
  };

  const validateEmail = () => {
    service.validateEmail({ email: userEmail }).then((res) => {
      if (res.success) {
        const isExists = res.data.exists;
        setEmailError(isExists);
        if (isExists || (!isExists && !isAlreadyDisabled)) {
          setIsDisabled(res.data.exists);
        }
      }
    });
  };

  return (
    <div className="pay-contact">
      <div className="pay-block_title">
        <div className="title">
          <span className="number">1</span> {buildLocale(currentTranslate, 'payPageContact')}
        </div>
        <UserProfilesDropdown setUserData={setUserData} updateStorageUser={updateStorageUser} setValue={setValue} />
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="form-item">
            <label htmlFor="email">{buildLocale(currentTranslate, 'orderContactEmail')}</label>
            <ItemInput
              errors={errors}
              className="form-control"
              defaultValue={userData?.email || ''}
              value={userData?.email || ''}
              placeholder={`Email*`}
              name={emailRegister.name}
              ref={emailRegister.ref}
              onChange={(e) => {
                changeData(email, e.target.value);
                emailRegister.onChange(e);
                if (isNonAuth) {
                  setUserEmail(e.target.value);
                }
              }}
              onBlur={(e) => {
                debCreateDraft();
                emailRegister.onBlur(e);
              }}
            />
            {emailError && (
              <span className="profile-form-error">
                {buildLocale(currentTranslate, 'emailExistsError')}{' '}
                <Link to={paths.login} style={{ color: '#dc3545', textDecoration: 'underline' }}>
                  {buildLocale(currentTranslate, 'emailExistsLink')}
                </Link>
              </span>
            )}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-item">
            <label htmlFor="phone">{buildLocale(currentTranslate, 'orderContactPhone')}</label>
            <PhoneInput
              inputProps={{
                errors: errors,
                name: phoneRegister.name,
                ref: phoneRegister.ref,
              }}
              value={userData?.phone || ''}
              enableSearch
              onBlur={(e) => {
                debCreateDraft();
                phoneRegister.onBlur(e);
              }}
              placeholder={buildLocale(currentTranslate, 'tIPhoneLabel')}
              country={'de'}
              onChange={(value, _, e) => {
                if (!isAuth) {
                  setSalePrice(null);
                }
                removeFromLocaleStorage(coupon);
                setCouponInfo('');
                changeData(phone, value);
                phoneRegister.onChange(e);
              }}
            />
            {errors && <ShowError errors={errors} name={'phone'} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDataComponent;
