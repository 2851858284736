import React, { useEffect, useState } from 'react';
import { useLessThen991 } from '../../../utils/mediaQuery';
import FiltersDesktop from './FiltersDesktop';
import FiltersMobile from './FiltersMobile';
import useFilterQueryParams from '../../../useHooks/useFilterQueryParams';
import { useSelector } from 'react-redux';
import { selectorsOther } from '../../../redux/other/otherReducer';

interface IProps {
  type: string;
  applyFilters: (empty?: boolean) => void;
  isLoading?: boolean;
  sortProducts?: (sortData: string | undefined) => void;
  id?: number;
  search?: string;
  hideSort?: boolean;
  disabledFilters?: string[];
}

const FilterComponent = (props: IProps) => {
  const { id, type, applyFilters, isLoading, sortProducts, search, hideSort, disabledFilters } = props;
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);
  const [isVisible, setIsVisible] = useState(false);
  const { brandsQuery, badgesQuery, attributesQuery } = useFilterQueryParams({ type, id, search });

  useEffect(() => {
    setIsVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLess991]);

  if (isVisible && isLess991) {
    return (
      <>
        <FiltersMobile
          applyFilters={applyFilters}
          hideSort={hideSort}
          brandsQuery={brandsQuery}
          badgesQuery={badgesQuery}
          attributesQuery={attributesQuery}
          disabledFilters={disabledFilters}
        />
      </>
    );
  }
  return (
    isVisible &&
    !isLess991 && (
      <FiltersDesktop
        isLoading={isLoading || false}
        sortProducts={sortProducts}
        hideSort={hideSort}
        brandsQuery={brandsQuery}
        badgesQuery={badgesQuery}
        attributesQuery={attributesQuery}
      />
    )
  );
};

export default FilterComponent;
