import { getLocaleStorageItem } from './localStorageHelper';
import {
  invoiceRequisitesBuildNumber,
  invoiceRequisitesCity,
  invoiceRequisitesCompany,
  invoiceRequisitesIsTheSameAsOrder,
  invoiceRequisitesPerson,
  invoiceRequisitesStreet,
  invoiceRequisitesZipCode,
} from '../constants/payData';

export const isInvoiceRequisitesDataValidToSave = () => {
  if (
    !getLocaleStorageItem(invoiceRequisitesPerson)?.length &&
    !getLocaleStorageItem(invoiceRequisitesCompany)?.length
  ) {
    return false;
  }

  return (
    getLocaleStorageItem(invoiceRequisitesCity)?.length &&
    /^\d{5}$/.test(getLocaleStorageItem(invoiceRequisitesZipCode) || '') &&
    getLocaleStorageItem(invoiceRequisitesStreet)?.length &&
    getLocaleStorageItem(invoiceRequisitesBuildNumber)?.length
  );
};

export const canInvoiceRequisitesDataBeProcessing = () => {
  const checkboxState = getLocaleStorageItem(invoiceRequisitesIsTheSameAsOrder);
  return checkboxState && checkboxState !== 'true';
};

export const isInvoiceRequisitesDataAvailable = () => {
  return canInvoiceRequisitesDataBeProcessing() && isInvoiceRequisitesDataValidToSave();
};
