import React, { useState } from 'react';
import ContainerModal from '../../Modals/ContainerModal';
import { IContact } from '../../../typings/IContact';
import { useDispatch, useSelector } from 'react-redux';
import { useAxios } from '../../../useHooks/useAxios';
import service from '../../../services/service';
import useDidUpdateEffect from '../../../useHooks/useDidUpdateEffect';
import { actionsUser } from '../../../redux/user/userReducer';
import ContactCard from './ContactCard';
import { getIsCustomPhone, getWithoutCodePhone } from '../../../utils/normalizePhone';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';
import FormContact from '../../Forms/FormContact';

interface IFieldAddContactProps {
  defaultContact?: IContact;
}

const FieldAddContact = React.memo(({ defaultContact }: IFieldAddContactProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const isAdd = defaultContact === undefined;
  const dispatch = useDispatch<any>();
  const { data: contact, isLoading, request } = useAxios(isAdd ? service.addContact : service.updateContact);
  const idForm = 'id_form_contact';
  const titleModal = isAdd
    ? buildLocale(currentTranslate, 'btnTextAddContact')
    : buildLocale(currentTranslate, 'btnTextChangeContact');
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((s) => !s);
  };

  const onSubmit = async (data) => {
    data.isPhoneCustom = getIsCustomPhone(data.phone);
    data.phone = getWithoutCodePhone(data.phone);
    if (!isAdd) {
      data.id = defaultContact!.id;
    }

    await request(data);
  };

  useDidUpdateEffect(() => {
    if (contact) {
      dispatch(isAdd ? actionsUser.addContact(contact) : actionsUser.updateContact(contact));
      toggle();
    }
  }, [contact]);

  return (
    <React.Fragment>
      {isAdd ? (
        <button type="button" onClick={toggle} className="link-dashed">
          + {buildLocale(currentTranslate, 'btnTextAddContact')}
        </button>
      ) : (
        <ContactCard contact={defaultContact!} onClick={toggle} />
      )}
      <ContainerModal
        title={titleModal}
        idForm={idForm}
        isAdd={isAdd}
        isLoading={isLoading}
        isOpen={isOpen}
        toggle={toggle}>
        <FormContact idForm={idForm} onSubmit={onSubmit} defaultContact={defaultContact} />
      </ContainerModal>
    </React.Fragment>
  );
});

export default FieldAddContact;
