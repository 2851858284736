import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { comment } from '../../../constants/payData';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';
import { setLocaleStorageItem, getLocaleStorageItem } from '../../../utils/localStorageHelper';

interface IProps {
  debCreateDraft: () => void;
}

const CommentDataComponent = (props: IProps) => {
  const { debCreateDraft } = props;
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const [commentValue, setCommentValue] = useState('');

  useEffect(() => {
    const localeComment = getLocaleStorageItem(comment);
    setCommentValue(localeComment || '');
  }, []);

  const changeComment = (value: string) => {
    setCommentValue(value);
    setLocaleStorageItem(comment, value);
  };

  return (
    <div>
      <div className="pay-block_title">
        <div className="title">
          <span className="number">4</span> {buildLocale(currentTranslate, 'cartComment')}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-item">
            <label>{buildLocale(currentTranslate, 'cartComment')}</label>
            <textarea
              className="form-control"
              value={commentValue}
              onChange={(e) => {
                changeComment(e.target.value);
              }}
              onBlur={debCreateDraft}
              placeholder={buildLocale(currentTranslate, 'comment')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentDataComponent;
