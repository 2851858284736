import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectorsLocale } from '../../../../../redux/locale/localeReducer';
import { buildLocale } from '../../../../../utils/buildLocale';
import CloseTopBtn from '../CloseTopBtn';
import { actionsFilters, selectorsFilters } from '../../../../../redux/filters/filtersReducer';
import { SortingValues } from '../../../../../typings/CustomTypes/SortingValues';

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const SortComponentMobile = (props: IProps) => {
  const { isOpen, setIsOpen } = props;
  const dispatch: any = useDispatch();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const sortDirectionFilter = useSelector(selectorsFilters.getSortDirection);

  const onClose = () => {
    setIsOpen(false);
  };

  const changeSorting = (sortData: string | undefined) => {
    if (sortData === undefined) {
      dispatch(actionsFilters.setSortDirection(undefined));
      setIsOpen(false);
      return;
    }

    dispatch(actionsFilters.setSortDirection(sortData));
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} className="modal-filter">
      <div className="modal-filter_container">
        <CloseTopBtn onClose={onClose} />
        <p className="title">{buildLocale(currentTranslate, 'filterModalTitle')}</p>
        <form>
          <div className="form-radio">
            <input
              type="radio"
              id="default"
              checked={sortDirectionFilter === undefined}
              onChange={() => changeSorting(undefined)}
            />
            <label htmlFor="default">{buildLocale(currentTranslate, 'categoriesFilterPopular')}</label>
          </div>
          <div className="form-radio">
            <input
              type="radio"
              id="highPrice"
              checked={!!sortDirectionFilter?.length && sortDirectionFilter.includes(SortingValues.ascPrice)}
              onChange={() => changeSorting(SortingValues.ascPrice)}
            />
            <label htmlFor="highPrice">{buildLocale(currentTranslate, 'highPriceFilter')}</label>
          </div>
          <div className="form-radio">
            <input
              type="radio"
              id="lowPrice"
              checked={!!sortDirectionFilter?.length && sortDirectionFilter.includes(SortingValues.descPrice)}
              onChange={() => changeSorting(SortingValues.descPrice)}
            />
            <label htmlFor="lowPrice">{buildLocale(currentTranslate, 'lowPriceFilter')}</label>
          </div>
          <div className="form-radio">
            <input
              type="radio"
              id="lowDiscountPercentSorting"
              checked={!!sortDirectionFilter?.length && sortDirectionFilter.includes(SortingValues.descDiscount)}
              onChange={() => changeSorting(SortingValues.descDiscount)}
            />
            <label htmlFor="lowDiscountPercentSorting">
              {buildLocale(currentTranslate, 'lowDiscountPercentSorting')}
            </label>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SortComponentMobile;
