import React from 'react';
import { formatTime, useFormattingContext } from '../../../context/FormattingContext';
import { TypeDelivery } from '../../../constants/constantsId';
import { formatAddress } from '../../../utils/formatAddress';
import { IOrderFull } from '../../../typings/IOrderItem';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { useSelector } from 'react-redux';
import { buildLocale } from '../../../utils/buildLocale';
import OrderedProductItem from './OrderedProductItem';
import { isMetroShop } from '../../../constants/shops';
import config from '../../../config';
import { getClientFullName } from '../../../utils/clientNameHelper';

interface IOrderPartItemProps {
  data: IOrderFull;
  onClose: () => any;
}

const OrderPartItem = ({ data, onClose }: IOrderPartItemProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const { formatPrice, longFormatDate } = useFormattingContext();
  const isMetro = isMetroShop(config.shopId);
  const isCourier = data.deliveryType.code === TypeDelivery.courier;
  const purchases = data.purchases;
  const client = { ...data.client, ...data.contact };
  const courier = data.deliveryPackage && data.deliveryPackage.courier;
  const freeBag = data.freeBag;
  const coupon = data.coupon;
  const comment = data.comment;
  const creditsUsed = data.creditsUsed ? +data.creditsUsed : 0;
  const deliveryPrice = data.deliveryCost ? +data.deliveryCost : 0;
  const bottleTotalDeposit = data.bottleTotalDeposit ? +data.bottleTotalDeposit : 0;
  const rackTotalDeposit = data.rackTotalDeposit ? +data.rackTotalDeposit : 0;
  const totalWeightDeposit = data.totalWeightDeposit ? +data.totalWeightDeposit : 0;
  const totalPrice = data.totalPrice ? +data.totalPrice : 0;
  const totalSecurityDeposit = data.totalSecurityDeposit ? +data.totalSecurityDeposit : 0;
  const totalWithSecurityDeposit = data.totalWithSecurityDeposit ? +data.totalWithSecurityDeposit : 0;
  const shownPrice =
    ['newWeb', 'new', 'confirm', 'inprogress', 'ready', 'reject'].includes(data?.orderStatus?.code) &&
    totalWithSecurityDeposit
      ? totalWithSecurityDeposit
      : totalPrice;

  return (
    <div className="profile-order_history">
      <div onClick={onClose} className="profile-order_history-link">
        <span>{buildLocale(currentTranslate, 'profileMyOrder')}</span>
      </div>
      {purchases.map(({ product, count, comment, productPrice, weight }) => (
        <OrderedProductItem
          key={product.id}
          product={product}
          count={count}
          weight={weight}
          comment={comment}
          productPrice={productPrice}></OrderedProductItem>
      ))}
      <div className="profile-order_delivery">
        {bottleTotalDeposit > 0 && (
          <p className="basket-block_bottle">
            <span>{buildLocale(currentTranslate, 'cartTotalBottleDeposit')}</span>
            <span>{formatPrice(bottleTotalDeposit)}</span>
          </p>
        )}
        {rackTotalDeposit > 0 && (
          <p className="basket-block_bottle">
            <span>{buildLocale(currentTranslate, 'cartTotalRackDeposit')}</span>
            <span>{formatPrice(rackTotalDeposit)}</span>
          </p>
        )}
        {totalWeightDeposit > 0 && (
          <p className="basket-block_bottle">
            <span>{buildLocale(currentTranslate, 'cartTotalWeightDeposit')}</span>
            <span>{formatPrice(totalWeightDeposit)}</span>
          </p>
        )}
        {isCourier && (
          <p className="delivery">
            <span>{buildLocale(currentTranslate, 'btnDelivery')}</span>
            <span>{formatPrice(deliveryPrice)}</span>
          </p>
        )}
        {creditsUsed > 0 && (
          <p className="delivery">
            <span>{buildLocale(currentTranslate, 'creditsProfile')}:</span>
            <span>{formatPrice(creditsUsed)}</span>
          </p>
        )}
        {coupon && (
          <p className="delivery">
            <span>{buildLocale(currentTranslate, 'couponHistory')}</span>
            <span>{`${coupon.amount} ${coupon.type.code === 'fix' ? '€' : '%'}`}</span>
          </p>
        )}
        {totalSecurityDeposit > 0 && (
          <p className="basket-block_bottle">
            <span>{buildLocale(currentTranslate, 'cartTotalSecurityDeposit')}</span>
            <span>{formatPrice(totalSecurityDeposit)}</span>
          </p>
        )}
        <p className="price">
          <span className="caption">{buildLocale(currentTranslate, 'cartSum')}</span>
          <span className="number">{formatPrice(shownPrice)}</span>
        </p>
      </div>
      <div className="row profile-order_info">
        <div className="col-xl-7">
          <p className="title">{buildLocale(currentTranslate, 'commonFullInformation')}</p>
          <ul className="profile-order_info-info">
            <li>
              <span className="caption">{buildLocale(currentTranslate, 'tIPhoneLabel')}</span> {client.phone}
            </li>
            <li>
              <span className="caption">{buildLocale(currentTranslate, 'commonReciever')}</span>{' '}
              {getClientFullName(client.fullName, client.firstName, client.lastName)}
            </li>
            {isMetro && client.company && (
              <li>
                <span className="caption">{buildLocale(currentTranslate, 'tICompanyLabel')}</span> {client.company}
              </li>
            )}
            {isCourier ? (
              <React.Fragment>
                <li>
                  <span className="caption">{buildLocale(currentTranslate, 'commonAddress')}</span>
                  <p className="address-order">{data.orderAddress || formatAddress(data.address, currentTranslate)}</p>
                </li>
                {data.address && data.address.notes && (
                  <li>
                    <span className="caption">
                      {buildLocale(currentTranslate, 'cartComment')} ({buildLocale(currentTranslate, 'commonAddress')})
                    </span>{' '}
                    {data.address.notes}
                  </li>
                )}
              </React.Fragment>
            ) : (
              <li>
                <span className="caption">{buildLocale(currentTranslate, 'commonSellPoint')}</span>{' '}
                {data.sellPoint.name}
              </li>
            )}
            <li>
              <span className="caption">{buildLocale(currentTranslate, 'commonPayment')}</span>{' '}
              {data.payments.paymentType.type}
            </li>
            <li>
              <span className="caption">{buildLocale(currentTranslate, 'commonTypeDelivery')}</span>{' '}
              {data.deliveryType.type}
            </li>
            {freeBag !== null && (
              <li>
                <span className="caption">{buildLocale(currentTranslate, 'freeBag')}</span>
                {freeBag ? buildLocale(currentTranslate, 'yes') : buildLocale(currentTranslate, 'no')}
              </li>
            )}
            {comment && (
              <li>
                <span className="caption">{buildLocale(currentTranslate, 'cartComment')}</span>
                {comment}
              </li>
            )}
          </ul>
        </div>
        <div className="col-xl-5">
          <p className="title">{buildLocale(currentTranslate, 'btnHistory')}</p>
          <ul className="profile-order_info-history">
            {data.orderStatusHistories
              .sort((a, b) => b.id - a.id)
              .map((h) => {
                const data = new Date(h.setDate);
                const formattingDate = `${longFormatDate(data)} / ${formatTime.format(data)}`;
                return (
                  <li key={h.id}>
                    <span className="caption">{formattingDate}</span>
                    <span className={`status status-${h.orderStatus.code}`}>{h.orderStatus.status}</span>
                  </li>
                );
              })}
          </ul>
          {courier && (
            <div className="profile-order_courier">
              <p className="top">{buildLocale(currentTranslate, 'commonCourier')}</p>
              <p className="name">{getClientFullName(courier.fullName, courier.firstName, courier.lastName)}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderPartItem;
