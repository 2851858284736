export const getClientFullName = (
  fullName: string | null | undefined,
  firstName: string | null | undefined,
  lastName: string | null | undefined,
): string => {
  if (!!fullName?.length) {
    return fullName;
  }
  if (!!firstName?.length) {
    return `${firstName}${!!lastName?.length ? ' ' + lastName : ''}`;
  }

  return '';
};

export const getClientShortNameWithPoint = (fullName: string | null): string => {
  if (!fullName?.length) {
    return '';
  }
  const nameParts = fullName.split(' ');
  const lastName = nameParts?.length > 1 ? nameParts.pop() : '';
  const endOfName = !!lastName?.length ? ` ${lastName.substring(0, 1)}.` : '';

  return `${nameParts.at(0)}${endOfName}`;
};
