import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { card, invoice, method, paypal } from '../../../constants/payData';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';
import { getLocaleStorageItem, setLocaleStorageItem } from '../../../utils/localStorageHelper';
import useCreateDraft from '../../../useHooks/useCreateDraft';
import { selectorsUser } from '../../../redux/user/userReducer';

const PaymentDataComponent = () => {
  const createDraft = useCreateDraft();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const user = useSelector(selectorsUser.getUser);
  const [paymentMethod, setPaymentMethod] = useState('');
  const isStripeActive = user?.isStripeActive;
  const isPaypalActive = user?.isPaypalActive;
  const isInvoiceActive = user?.isInvoiceActive;

  useEffect(() => {
    const currentMethod = getLocaleStorageItem(method);
    if (!user) {
      if (!currentMethod || currentMethod === invoice) {
        setLocaleStorageItem(method, card);
        setPaymentMethod(card);
        return;
      }
      setPaymentMethod(currentMethod);
    }

    if (user) {
      if (!currentMethod) {
        if (isStripeActive) {
          setLocaleStorageItem(method, card);
          setPaymentMethod(card);
          return;
        }
        if (isPaypalActive) {
          setLocaleStorageItem(method, paypal);
          setPaymentMethod(paypal);
          return;
        }
        if (isInvoiceActive) {
          setLocaleStorageItem(method, invoice);
          setPaymentMethod(invoice);
          return;
        }
      }

      if (currentMethod) {
        if (currentMethod === card && !isStripeActive) {
          if (isPaypalActive) {
            setLocaleStorageItem(method, paypal);
            setPaymentMethod(paypal);
            return;
          }
          if (isInvoiceActive) {
            setLocaleStorageItem(method, invoice);
            setPaymentMethod(invoice);
            return;
          }
          setLocaleStorageItem(method, '');
          return;
        }
        if (currentMethod === paypal && !isPaypalActive) {
          if (isStripeActive) {
            setLocaleStorageItem(method, card);
            setPaymentMethod(card);
            return;
          }
          if (isInvoiceActive) {
            setLocaleStorageItem(method, invoice);
            setPaymentMethod(invoice);
            return;
          }
          setLocaleStorageItem(method, '');
          return;
        }
        if (currentMethod === invoice && !isInvoiceActive) {
          if (isStripeActive) {
            setLocaleStorageItem(method, card);
            setPaymentMethod(card);
            return;
          }
          if (isPaypalActive) {
            setLocaleStorageItem(method, paypal);
            setPaymentMethod(paypal);
            return;
          }
          setLocaleStorageItem(method, '');
          return;
        }
        setPaymentMethod(currentMethod);
      }
    }
  }, [user, isStripeActive, isPaypalActive, isInvoiceActive]);

  const changePaymentMethod = (pay: string) => {
    setPaymentMethod(pay);
    setLocaleStorageItem(method, pay);
  };

  return (
    <div className="pay-payment">
      <div className="pay-block_title">
        <div className="title">
          <span className="number">6</span> {buildLocale(currentTranslate, 'payPagePaymentMetod')}
        </div>
      </div>
      <div className="pay-payment_form">
        {!user && (
          <>
            <div className="form-radio">
              <input
                id="card"
                type="radio"
                checked={paymentMethod === card}
                onChange={() => {
                  changePaymentMethod(card);
                  createDraft();
                }}
              />
              <label htmlFor="card">{buildLocale(currentTranslate, 'payPageCard')}</label>
            </div>
            <div className="form-radio">
              <input
                id="paypal"
                type="radio"
                checked={paymentMethod === paypal}
                onChange={() => {
                  changePaymentMethod(paypal);
                  createDraft();
                }}
              />
              <label htmlFor="paypal">{buildLocale(currentTranslate, 'payPagePayPal')}</label>
            </div>
          </>
        )}
        {isStripeActive && (
          <div className="form-radio">
            <input
              id="card"
              type="radio"
              checked={paymentMethod === card}
              onChange={() => {
                changePaymentMethod(card);
                createDraft();
              }}
            />
            <label htmlFor="card">{buildLocale(currentTranslate, 'payPageCard')}</label>
          </div>
        )}
        {isPaypalActive && (
          <div className="form-radio">
            <input
              id="paypal"
              type="radio"
              checked={paymentMethod === paypal}
              onChange={() => {
                changePaymentMethod(paypal);
                createDraft();
              }}
            />
            <label htmlFor="paypal">{buildLocale(currentTranslate, 'payPagePayPal')}</label>
          </div>
        )}
        {isInvoiceActive && (
          <div className="form-radio">
            <input
              id="invoice"
              type="radio"
              checked={paymentMethod === invoice}
              onChange={() => {
                changePaymentMethod(invoice);
                createDraft();
              }}
            />
            <label htmlFor="invoice">{buildLocale(currentTranslate, 'payPageInvoice')}</label>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentDataComponent;
