import React, { useEffect, useState } from 'react';
import Product from '../Products/Product';
import Carousel from 'react-multi-carousel';
import { isBrowser } from '../../utils/isBrowser';
import { carouselCategories } from '../../constants/configCarousel';
import { ButtonGroup } from '../Products/SliderProducts';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import { IProduct } from '../../typings/IProduct';
import { selectorsOther } from '../../redux/other/otherReducer';
import { blockBodyScroll } from '../../utils/blockBodyScroll';
import { selectorsFilters } from '../../redux/filters/filtersReducer';
import { useLessThen991 } from '../../utils/mediaQuery';
import useApi from 'react-use-api';
import queries from '../../services/queries';
import { selectorsDelivery } from '../../redux/delivery/deliveryReducer';
import { selectorsCategory } from '../../redux/category/categoryReducer';
import { useNavigate, useNavigationType } from 'react-router';
import useFilterDataFromSearchParams from '../../useHooks/useFilterDataFromSearchParams';
import useFilterQueryParams from '../../useHooks/useFilterQueryParams';
import { FILTER_TYPE } from '../../typings/IFilters';
import getSortDataForFilter from '../../utils/getSortDataForFilter';
import { v5 as uuidv5 } from 'uuid';
import { useLocation } from 'react-router-dom';
import isLocationForFirstLoadedPage from '../../utils/isLocationForFirstLoadedPage';

interface IProps {
  category?: any;
  limit?: number;
  setLimit?: () => void;
  sortingData?: string | undefined;
  route?: string;
  name?: string;
  id?: number;
  parentId?: number;
}

const CustomSlider = (props: IProps) => {
  const { route, name, id, parentId, category, setLimit } = props;
  const currentId = Number(id || category.id);
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const isInCourierArea = useSelector(selectorsDelivery.isInCourierArea);
  const maxProductsInSlider = useSelector(selectorsOther.getMaxProductsInSlider);
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const childCategories = useSelector(selectorsCategory.getCategoriesChildrenById(id));
  const [top, setTop] = useState(6);
  const { brandsQuery, badgesQuery, attributesQuery } = useFilterQueryParams({
    type: FILTER_TYPE.OWNCATEGORY,
    id: parentId || currentId,
    skip: true,
  });
  const { searchQueryData } = useFilterDataFromSearchParams(brandsQuery, badgesQuery, attributesQuery);

  const selectedBrands = useSelector(selectorsFilters.getSelectedBrands);
  const selectedBadges = useSelector(selectorsFilters.getSelectedBadges);
  const selectedAllergich = useSelector(selectorsFilters.getSelectedAllergich);
  const selectedNutrion = useSelector(selectorsFilters.getSelectedNutrion);
  const hasPriceRanges = useSelector(selectorsFilters.getHasPriceRanges);
  const hasBadges = useSelector(selectorsFilters.getHasBadges);
  const hasSalePrice = useSelector(selectorsFilters.getHasSalePrice);
  const searchFilter = useSelector(selectorsFilters.getSearchFilter);
  const selectedSort = useSelector(selectorsFilters.getSortDirection);
  const isBot = useSelector(selectorsOther.getIsBot);
  const location = useLocation();

  const getActualSearchData = () => {
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      return searchQueryData.isEmpty ? searchFilter || '' : searchQueryData.searchFilter || '';
    }
    return searchFilter || '';
  };
  const tidNameSpace = uuidv5(location.pathname, uuidv5.URL);
  const actualSearchData = getActualSearchData();
  const tid = actualSearchData?.length ? uuidv5(actualSearchData, tidNameSpace) : '';

  const makeFiltersFromSearchParams = (filters: any) => {
    const brandsFilter = searchQueryData.isEmpty ? selectedBrands : searchQueryData.selectedBrands;
    const badgesFilter = searchQueryData.isEmpty ? selectedBadges : searchQueryData.selectedBadges;

    return {
      ...filters,
      brands: brandsFilter?.length ? brandsFilter.slice(0).map((brand) => brand.id) : null,
      badges: badgesFilter?.length ? badgesFilter.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: searchQueryData.isEmpty ? selectedAllergich : searchQueryData.selectedAllergich,
      selectedNutrion: searchQueryData.isEmpty ? selectedNutrion : searchQueryData.selectedNutrion,
      hasPriceRanges: searchQueryData.isEmpty ? hasPriceRanges : searchQueryData.hasPriceRanges,
      hasBadges: searchQueryData.isEmpty ? hasBadges : searchQueryData.hasBadges,
      hasSalePrice: searchQueryData.isEmpty ? hasSalePrice : searchQueryData.hasSalePrice,
      searchFilter: actualSearchData,
    };
  };

  const getCategoryProducts = () => {
    let filters = {
      brands: selectedBrands?.length ? selectedBrands.slice(0).map((brand) => brand.id) : null,
      badges: selectedBadges?.length ? selectedBadges.slice(0).map((badge) => badge.id) : null,
      selectedAllergich: selectedAllergich,
      selectedNutrion: selectedNutrion,
      hasPriceRanges: hasPriceRanges,
      hasBadges: hasBadges,
      hasSalePrice: hasSalePrice,
      searchFilter: searchFilter || '',
    };
    // if reloading page
    if (isLocationForFirstLoadedPage(location.key, navigationType)) {
      filters = makeFiltersFromSearchParams(filters);
    }

    return queries.getCustomCategoryProductsVisible(
      currentId,
      true,
      getSortDataForFilter(searchQueryData, selectedSort),
      filters,
      isInCourierArea,
      tid,
      maxProductsInSlider ? +maxProductsInSlider : 20,
      true,
    );
  };
  const [products] = useApi<IProduct[]>(getCategoryProducts());
  const isDesktop = useSelector(selectorsOther.isDesktop);
  const isLess991 = useLessThen991(isDesktop);

  const setSliderProducts = () => {
    const screenWidth = window.screen.width;

    if (screenWidth < 575) return 2;
    if (screenWidth < 849) return 3;
    if (screenWidth < 1199) return 4;
    return 6;
  };

  useEffect(() => {
    if (isBrowser) {
      setTop(setSliderProducts());
    }
  }, []);

  useEffect(() => {
    if (!products?.length && setLimit) {
      setLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    const carouselItems = document.getElementsByClassName('carousel-con');
    if (carouselItems && carouselItems.length > 0) {
      for (let i = 0; i < carouselItems.length; i++) {
        let xMove = 0;
        const touchEnd = () => {
          blockBodyScroll(false);
          xMove = 0;
        };
        const touchMove = (event) => {
          const evt = typeof event.originalEvent === 'undefined' ? event : event.originalEvent;
          const touch = evt.touches[0] || evt.changedTouches[0];
          const x = touch.pageX;
          const y = touch.pageY;
          if (x !== xMove) {
            if (xMove && (x - xMove > 7 || x - xMove < -7)) {
              blockBodyScroll(true);
              return;
            }
            xMove = x;
          }
          return y;
        };

        carouselItems[i].addEventListener('touchend', touchEnd, { passive: true });
        carouselItems[i].addEventListener('touchmove', touchMove, { passive: true });
      }
    }
  }, [products]);

  if (Array.isArray(products) && products.length === 0) {
    return null;
  }

  const itemNavigate = (e: React.MouseEvent<HTMLElement>, navigateLink: string) => {
    e.preventDefault();
    const historyItem = `${location.pathname}${location.search}`;
    const historyData = location?.state?.from?.length ? [...location.state.from, historyItem] : [historyItem];
    const historyFiltersData = location?.state?.filters;
    navigate(`${navigateLink}${location.search}`, { state: { from: historyData, filters: historyFiltersData } });
  };

  return (
    <section className="section-also">
      <div className="container">
        <h2>
          <a
            href={`/${route || 'products'}/${currentId}`}
            className="slider-main-title"
            onClick={(e) => itemNavigate(e, `/${route || 'products'}/${currentId}`)}>
            {name || category.name}
          </a>
        </h2>

        <div className="subcategory-list">
          {!!childCategories?.length && (
            <Carousel
              autoPlay={false}
              ssr={true}
              responsive={carouselCategories}
              focusOnSelect={false}
              shouldResetAutoplay={false}
              swipeable={true}
              draggable={true}
              arrows={false}
              showDots={false}
              deviceType="desktop">
              {childCategories.map((childCategory) => (
                <a
                  href={
                    isLess991 ? `/categories_page/own_categories/${childCategory.id}` : `/products/${childCategory.id}`
                  }
                  onClick={(e) =>
                    itemNavigate(
                      e,
                      isLess991
                        ? `/categories_page/own_categories/${childCategory.id}`
                        : `/products/${childCategory.id}`,
                    )
                  }
                  className="subcategory-link"
                  key={childCategory.id}
                  onDragStart={(e) => {
                    e.preventDefault();
                  }}>
                  {childCategory.name || ''}
                </a>
              ))}
            </Carousel>
          )}
        </div>

        <a
          href={isLess991 ? `/categories_page/own_categories/${currentId}` : `/${route || 'products'}/${currentId}`}
          className="btn-more"
          onClick={(e) => {
            itemNavigate(
              e,
              isLess991 ? `/categories_page/own_categories/${currentId}` : `/${route || 'products'}/${currentId}`,
            );
          }}>
          {buildLocale(currentTranslate, 'common.categories.showMore')}
        </a>
        {!!products?.length && (
          <Carousel
            autoPlay={false}
            ssr={true}
            responsive={carouselCategories}
            itemClass="carousel-item-li"
            containerClass="carousel-con"
            focusOnSelect={false}
            shouldResetAutoplay={false}
            swipeable={true}
            draggable={true}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside
            showDots={false}
            afterChange={(previousSlide, { currentSlide }) => {
              setTop(currentSlide + setSliderProducts());
            }}
            renderDotsOutside
            dotListClass="owl-dots"
            deviceType="desktop">
            {products.map((product: any, index) => {
              return (
                <div key={`${product.id}${id}${index}`} className="catalog-item">
                  <Product product={product} isDefaultImg={isBot ? false : index > top - 1} />
                </div>
              );
            })}
          </Carousel>
        )}
      </div>
    </section>
  );
};

function moviePropsAreEqual(prevMovie, nextMovie) {
  return prevMovie.sortingData === nextMovie.sortingData && prevMovie.productsTrigger === nextMovie.productsTrigger;
}

export default React.memo(CustomSlider, moviePropsAreEqual);
