import React, { useState } from 'react';
import ContainerModal from '../../Modals/ContainerModal';
import { useAxios } from '../../../useHooks/useAxios';
import service from '../../../services/service';
import useDidUpdateEffect from '../../../useHooks/useDidUpdateEffect';
import { useDispatch, useSelector } from 'react-redux';
import { actionsUser, selectorsUser } from '../../../redux/user/userReducer';
import { IAddressRedux } from '../../../typings/CustomTypes/IAddressRedux';
import { IAddress } from '../../../typings/IAddress';
import AddressCard from './AddressCard';
import FormAddress from '../../Forms/FormAddress';
import { getConvertDataToFetch } from '../../../utils/formatAddress';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';
import { cities } from '../../../constants/cities';

interface IFieldAddAddressProps {
  defaultAddress?: IAddress;
}

const FieldAddAddress = ({ defaultAddress }: IFieldAddAddressProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const isAdd = defaultAddress === undefined;
  const dispatch = useDispatch<any>();
  const { data: address, isLoading, request } = useAxios(isAdd ? service.addAddress : service.updateAddress);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const addresses = useSelector(selectorsUser.getAddresses);
  const idForm = 'id_form_address';
  const titleModal = isAdd
    ? buildLocale(currentTranslate, 'btnTextAddAddress')
    : buildLocale(currentTranslate, 'btnTextChangeAddress');

  const toggle = () => {
    setIsOpen((s) => !s);
  };

  const onSubmit = async (data: IAddressRedux) => {
    const currentAddress = addresses.find(
      (address) =>
        // (address.entrance === data.entrance || (!!!address.entrance && !!!data.entrance)) &&
        address.zipCode === data.zipCode &&
        address.buildNumber === data.buildNumber &&
        // (address.flatNumber === data.flatNumber || (!!!address.flatNumber && !!!data.flatNumber)) &&
        (address.city === cities.find((c) => c.value === data.city)?.label || address.city === data.city) &&
        // (address.floor === data.floor || (!!!address.floor && !!!data.floor)) &&
        address.street === data.street,
    );
    if (currentAddress) {
      setError(true);
      return;
    }
    const fetchData = getConvertDataToFetch(data) as any;
    if (!isAdd) {
      fetchData.id = defaultAddress!.id;
    }
    setError(false);
    await request(fetchData);
  };

  useDidUpdateEffect(() => {
    if (address) {
      dispatch(isAdd ? actionsUser.addAddress(address) : actionsUser.updateAddress(address));
      toggle();
    }
  }, [address]);

  return (
    <React.Fragment>
      {isAdd ? (
        <button type="button" onClick={toggle} className="link-dashed">
          + {buildLocale(currentTranslate, 'btnTextAddAddress')}
        </button>
      ) : (
        <AddressCard address={defaultAddress!} onClick={toggle} />
      )}
      {isOpen && (
        <ContainerModal
          title={titleModal}
          idForm={idForm}
          isOpen={isOpen}
          isLoading={isLoading}
          toggle={toggle}
          isAdd={isAdd}
          className="modal-body-custom">
          <FormAddress idForm={idForm} onSubmit={onSubmit} defaultAddress={defaultAddress} error={error} />
        </ContainerModal>
      )}
    </React.Fragment>
  );
};

export default FieldAddAddress;
