import React from 'react';
import ItemInput from './Components/ItemInput';
import validation from '../../utils/validation';
import { IContact } from '../../typings/IContact';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../redux/locale/localeReducer';
import { buildLocale } from '../../utils/buildLocale';
import ShowError from './Components/ShowError';
import { isMetroShop } from '../../constants/shops';
import config from '../../config';

interface IFormContactProps {
  idForm: string;
  defaultContact?: Omit<IContact, 'id'>;
  onSubmit: (d: IContact) => any;
}

const FormContact = ({ defaultContact, idForm, onSubmit }: IFormContactProps) => {
  const currentTranslate = useSelector(selectorsLocale.getTranslate);
  const isMetro = isMetroShop(config.shopId);
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<IContact>({
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    defaultValues: defaultContact,
  });
  const phoneRegister = register('phone', validation.phone);

  return (
    <form id={idForm} onSubmit={handleSubmit(onSubmit)} className="modal-form-profile">
      <ItemInput
        errors={errors}
        label={buildLocale(currentTranslate, 'tINameLabel')}
        {...register('fullName', validation.required)}
      />
      <ItemInput
        errors={errors}
        label={buildLocale(currentTranslate, 'tIEmailLabel')}
        {...register('email', validation.email)}
      />
      {isMetro && (
        <ItemInput errors={errors} label={buildLocale(currentTranslate, 'tICompanyLabel')} {...register('company')} />
      )}
      <div className="form-item">
        <label htmlFor="phone">{buildLocale(currentTranslate, 'tIPhoneLabel')}</label>
        <PhoneInput
          inputProps={{
            errors,
            name: phoneRegister.name,
            ref: phoneRegister.ref,
          }}
          value={defaultContact ? defaultContact.phone : ''}
          enableSearch
          placeholder={buildLocale(currentTranslate, 'tIPhoneLabel')}
          country={'de'}
          onChange={(value, _, e) => phoneRegister.onChange(e)}
          onBlur={(e) => phoneRegister.onBlur(e)}
        />
        {errors && <ShowError errors={errors} name={'phone'} />}
      </div>
    </form>
  );
};

export default FormContact;
